
/* ===== Base ===== */

h2 {
  padding: .6rem;
  background-color: #EEE;
  margin-top: 1.5rem;
}

/* ===== Helpers ===== */

.titlecase {
  text-transform: capitalize;
}

/* ===== Layout ===== */

.app-container {
  max-width: 60rem;
  margin: 0 auto;
}

.errors-container h2 {
  background-color: hsl(348, 100%, 61%);
  color: #FFF;
}

.output-container h2 {
  background-color: hsl(141, 53%, 53%);
  color: #FFF;
}

.output-container table {
  font-size: small;
}
